/**
 * Module dependencies.
 */

import { CSSProperties, ReactNode, useRef } from 'react';
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity
} from 'framer-motion';

/*
 * `Props` type.
 */

type Props = {
  baseVelocity?: number;
  children: ReactNode;
  isInView: boolean;
  style?: CSSProperties;
};

/**
 * Export `ParallaxWrapper` component.
 */

export const ParallaxWrapper = (props: Props) => {
  const { baseVelocity = 1, children, isInView, ...rest } = props;

  const x = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, { damping: 50, stiffness: 400 });
  const velocityFactor = useTransform(smoothVelocity, [0, 10], [0, -3], {
    clamp: false
  });

  const directionFactor = useRef(1);

  useAnimationFrame((t, delta) => {
    if (isInView) {
      let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

      directionFactor.current = velocityFactor.get() <= 0 ? -1 : 1;

      moveBy += directionFactor.current * moveBy * velocityFactor.get();

      x.set(x.get() + moveBy);
    }
  });

  return (
    <motion.div {...rest} style={{ ...rest.style, x }}>
      {children}
    </motion.div>
  );
};
