/**
 * Module dependencies.
 */

import { TFunction } from 'i18next';

/**
 * Export `getDataFromTranslations` util.
 */

export function getDataFromTranslations<TData>(path: string, t: TFunction<'translation', undefined>) {
  try {
    const items = t(path, { returnObjects: true }) as Record<string, TData>;
    const data = Object.keys(items).map(key => items[key]);

    return {
      data,
      total: data?.length
    };
  } catch {
    return {
      data: [],
      total: 0
    };
  }
}
