/**
 * Module dependencies.
 */

import { NextSeo, NextSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { useRouter } from 'next/router';

/**
 * Constants.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Props` type.
 */

type Props = NextSeoProps;

/**
 * Export `SEO` component.
 */

export const SEO = (props: Props) => {
  const { asPath } = useRouter();

  return (
    <NextSeo
      canonical={absoluteUrlResolve(asPath.replace(/#.*/, '').replace(/\?.*/, ''))}
      nofollow={!isProduction}
      noindex={!isProduction}
      {...props}
    />
  );
};
