/**
 * Module dependencies.
 */

import { HTMLMotionProps, motion, useInView } from 'framer-motion';
import { ReactNode, useRef } from 'react';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  delayChildren?: number;
  margin?: string;
  shouldAnimate?: boolean;
  staggerChildren?: number;
  staggerMobile?: boolean;
} & HTMLMotionProps<'div'>;

/**
 * Export `AnimationController` component.
 */

export const AnimationController = (props: Props) => {
  const {
    children,
    delayChildren,
    margin = '-20% 0px',
    shouldAnimate = true,
    staggerChildren = 0.2,
    staggerMobile,
    ...rest
  } = props;

  const ref = useRef(null);
  const isInView = useInView(ref, { margin, once: true });
  const shouldStartAnimation = isInView && shouldAnimate;
  const isMobile = useBreakpoint(0, 'ms');

  const animateProps = !isMobile || staggerMobile;

  return (
    <motion.div
      {...rest}
      {...(animateProps && { animate: shouldStartAnimation ? 'animate' : 'initial' })}
      {...(animateProps && { initial: 'initial' })}
      {...(animateProps && {
        transition: {
          delayChildren,
          staggerChildren
        }
      })}
      ref={ref}
    >
      {children}
    </motion.div>
  );
};
