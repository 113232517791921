/**
 * Module dependencies.
 */

import 'swiper/css';
import 'swiper/css/pagination';
import { Children, ReactNode, forwardRef, useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { media } from '@untile/react-core/styles/media';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<SwiperProps, 'modules'> & {
  children: ReactNode;
  className?: string;
};

/**
 * `StyledSwiper` styled component.
 */

export const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    height: auto;
    width: calc(304px - var(--swiper-slide-gap));

    ${media.min.md`
      width: calc((100% / 3) - ((var(--swiper-slide-gap) * 2) / 3));
    `}
  }

  &[data-slides-total='1'] {
    .swiper-slide {
      width: 100%;
    }
  }

  &[data-slides-total='2'] {
    ${media.min.ms`
      .swiper-slide {
        width: calc(50% - var(--swiper-slide-gap) / 2);
      }
    `}
  }
`;

/**
 * Export `Slide` component.
 */

export const Slide = SwiperSlide;

/**
 * Export `Carousel` component.
 */

export const Carousel = forwardRef((props: Props, ref: any) => {
  const { children, ...rest } = props;
  const childrenCount = useMemo(() => Children.count(children), [children]);

  return (
    <StyledSwiper
      grabCursor
      modules={[Pagination]}
      ref={ref}
      slideToClickedSlide
      slidesPerView={'auto'}
      spaceBetween={'8px'}
      style={{ overflow: 'hidden' }}
      {...(childrenCount < 3 && { 'data-slides-total': childrenCount })}
      {...rest}
    >
      {children}
    </StyledSwiper>
  );
});

/**
 * `Carousel` display name.
 */

Carousel.displayName = 'Carousel';
