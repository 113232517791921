/**
 * Module dependencies.
 */

import { CSSProperties } from 'react';
import { HTMLMotionProps } from 'framer-motion';
import { ParallaxWrapper } from 'src/components/core/animations/parallax-scroll';
import { Svg } from 'src/components/core/svg';
import styled from 'styled-components';

/**
 * `IconProps` type.
 */

type IconProps = {
  baseVelocity?: number;
  icon: string;
  isInView: boolean;
  name: string;
  style?: CSSProperties;
} & HTMLMotionProps<'div'>;

/**
 * `IconCard` styled component.
 */

const IconCard = styled.div`
  align-items: center;
  background: var(--color-slate700);
  border-radius: var(--border-radius-xxs);
  color: var(--color-cyan600);
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  opacity: 0.4;
  padding: 6px;
  width: 80px;

  svg {
    margin-bottom: 12px;
    width: 22px;
  }

  span {
    font-size: 8px;
    text-transform: uppercase;
  }
`;

/**
 * Export `IconParallax` component.
 */

export const IconParallax = (props: IconProps) => {
  const { baseVelocity, icon, name, ...rest } = props;

  return (
    <ParallaxWrapper {...rest} baseVelocity={baseVelocity}>
      <IconCard>
        <Svg icon={icon} />
        <span>{name}</span>
      </IconCard>
    </ParallaxWrapper>
  );
};
