/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `BadgePrice` styled component.
 */

export const BadgePrice = styled.div`
  align-items: center;
  background: var(--gradient-primary--270-deg);
  border-radius: var(--border-radius-xs);
  color: var(--color-slate900);
  display: inline-grid;
  grid-template-areas:
    'price price-symbol'
    'vat vat';
  grid-template-rows: max-content 1fr;
  padding: calc(var(--space-xxs) / 2) 12px var(--space-xxs) 12px;

  &[data-variant='secondary'] {
    background: transparent;
    color: inherit;
  }
`;
