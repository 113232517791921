/**
 * Module dependencies.
 */

import { Content } from '@prismicio/client';
import { openGraph } from 'src/core/constants/seo-default-config';
import omit from 'lodash/omit';

/**
 * Export `FaqProps` type.
 */

export type FaqProps = Omit<Content.FaqDocumentData, 'active' | 'categories'>;

/**
 * Export `normalizePrismicSEO`.
 */

export function normalizePrismicSEO(data: any) {
  const title = data?.meta_title;
  const description = data?.meta_description;
  const keywords = data?.meta_keywords;
  const image = data?.meta_image;

  return {
    ...(description && { description }),
    openGraph: {
      images: [
        {
          alt: image?.alt || openGraph.images[0].alt,
          height: image?.dimensions?.height || openGraph.images[0].height,
          url: image?.url || openGraph.images[0].url,
          width: image?.dimensions?.width || openGraph.images[0].width
        }
      ],
      siteName: openGraph.siteName,
      url: openGraph.url
    },
    ...(keywords && {
      additionalMetaTags: [
        {
          content: keywords,
          name: 'keywords'
        }
      ]
    }),
    ...(title && { title })
  };
}

/**
 * Export `normalizeFAQs`.
 */

export function normalizeFAQs(faqs: Content.FaqDocument[], slug: string): FaqProps[] {
  return faqs
    .map(({ data }) => data)
    .map(data => ({
      ...data,
      // @ts-expect-error uid is not defined in the type
      categories: data.categories.map(({ category }) => category.uid)
    }))
    .filter(({ active, categories }) => active && categories.includes(slug))
    .map(data => omit(data, ['active', 'categories']));
}
